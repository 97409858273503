import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h2 style={{ textAlign: "center" }}>Today&apos;s math challange</h2>
      </header>
      <main className='main-content'>
        <h1>{Math.floor(Math.random() * 10000000000000000000)}</h1>
        <h1 style={{ textAlign: "center" }}>
          {["-", "+", "*", "/"][Math.floor(Math.random() * 3)]}
        </h1>
        <h1>{Math.floor(Math.random() * 10000000000000000000)}</h1>
      </main>
    </div>
  );
}

export default App;
